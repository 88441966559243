import React, { useEffect, useState } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeadRow,
  TableData
} from '../utils/Table';
import { BodyTable } from '../utils/BodyTable';
import { connect } from 'react-redux';
import { Toggle } from 'material-ui';
import IcoEdit from '../utils/img/ic-editar.svg';
import RemoveItemIco from '../utils/img/ic-excluir.svg';
import { DialogAlert } from '../utils/DailogComponentes';
import DialogApi from '../../Logicas/DialogApi';
import MenuHeader from './Components/MenuHeader/MenuHeader';
import MenuApi from '../../Logicas/MenuApi';
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc';
import Modal from 'react-awesome-modal';
import { MuiThemeProvider } from 'material-ui/styles';
import { Field, reduxForm, change } from 'redux-form';
import { minValue0, required } from '../validation/FormValidation';
import {
  ButtonDialogTag,
  LongTextLink,
  TextFieldDefault,
  TextFieldNumberInput
} from '../utils/FormsComponents';
import { Name } from './Menu.styles';
import "./menu.css";

export function Menu(props) {
  const [items, setItems] = useState([]);
  const [openEdit, setOpenEdit] = useState({
    id: '',
    kind: undefined,
    show: false,
    height: '260'
  });

  const [kind, setKind] = useState("external_link");

  const setItemTitle = title => props.dispatch(change('edititemmenu', 'title', title));
  const setItemLink = link => props.dispatch(change('edititemmenu', 'link', link));
  const setItemId = id => props.dispatch(change('edititemmenu', 'canvas_publication_id', id));

  useEffect(() => {
    props.getMenu();

    setItems(props.menuList);
  }, [props.menuList]);

  const arrayMoveMutate = (array, from, to) => {
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  };

  const arrayMove = (array, from, to) => {
    array = array.slice();
    arrayMoveMutate(array, from, to);

    return array;
  };

  const DragHandle = sortableHandle(() => (
    <TableData width="10%">
      <svg
        width="4"
        height="16"
        viewBox="0 0 4 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
          fill="#BDBDBD"
        />
      </svg>
    </TableData>
  ));

  const SortableItem = sortableElement(({ value, index }) => {
    return (
      <TableRow key={index}>
        {value.kind !== 'feed' && value.kind !== 'settings' ? (
          <DragHandle />
        ) : (
          <TableData width="10%" />
        )}

        <TableData width="70%">
          <div>
            {value.title}{' '}
            <Name>
              {value.name && value.name !== value.title
                ? `- ${value.name}`
                : ''}
            </Name>
          </div>
        </TableData>
        <TableData width="10%">
          {value.kind !== 'feed' && value.kind !== 'settings' && (
            <div style={{ marginTop: 5 }}>
              <Toggle
                thumbStyle={styles.thumbOff}
                trackStyle={styles.trackOff}
                thumbSwitchedStyle={styles.thumbSwitched}
                trackSwitchedStyle={styles.trackSwitched}
                labelStyle={styles.labelStyle}
                defaultToggled={value.active}
                onToggle={(event, index, values) =>
                  handleChange(value.id, index)
                }
              />
            </div>
          )}
        </TableData>
        <TableData width="10%">
          {value.kind !== 'feed' && value.kind !== 'settings' && (
            <div>
              <img
                onClick={() => {
                  setOpenEdit({
                    id: value.id,
                    kind: value.kind,
                    show: true,
                    height: value.kind === 'external_link' ? '371' : '260'
                  });
                  setItemTitle(value.title);
                  value.link !== undefined && setItemLink(value.link);
                  setItemId(value.canvas_publication_id);
                  // props.getTagEdit(value.tag.id);
                }}
                className="img-edit"
                src={IcoEdit}
                alt="Ícone de edição"
              />
              {(value.kind === 'external_link' || value.kind === "publication") && (
                <img
                  onClick={(e) => handleRemoveItem(e, value.id)}
                  style={{ marginLeft: '15px' }}
                  src={RemoveItemIco}
                  className="img-edit"
                  alt="remover"
                />
              )}
            </div>
          )}
        </TableData>
      </TableRow>
    );
  });

  const SortableContainer = sortableContainer(({ children }) => {
    return <TableBody>{children}</TableBody>;
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const array_move = arrayMove(
      items.length > 0 ? items : props.menuList,
      oldIndex,
      newIndex
    );

    if (array_move[0].kind !== 'feed' || array_move.at(-1).kind !== 'settings')
      return false;

    setItems(array_move);

    const arrMenus = {
      menus_ids: []
    };

    array_move.map(({ id }) => arrMenus.menus_ids.push(id));
    props.sortMenu(arrMenus);
  };

  const handleChange = (id, checked) => {
    props.editItemMenu({ active: checked }, id);
  };

  const styles = {
    block: {
      maxWidth: 250
    },
    toggle: {
      marginBottom: 16
    },
    thumbOff: {
      backgroundColor: '#ffcccc'
    },
    trackOff: {
      backgroundColor: '#ff9d9d'
    },
    thumbSwitched: {
      backgroundColor: '#1bab60'
    },
    trackSwitched: {
      backgroundColor: '#79dba9'
    },
    labelStyle: {
      color: 'red'
    }
  };

  const handleAddItem = (e) => {
    e.preventDefault();
    const { reset } = props;

    props.addItemMenu(e.target);

    reset();

    props.closeDialog();
  };

  const handleRemoveItem = (e, id) => {
    e.preventDefault();
    props.deleteItemMenu(id);
  };

  const handleEditItem = async (e) => {
    e.preventDefault();

    const { reset } = props;

    props.editItemMenu(e.target, openEdit.id);

    reset();

    setOpenEdit({ id: '', kind: undefined, show: false, height: '260' });

    props.getMenu();
    setItems(props.menuList);
  };

  const handleExternalOrPublication = (e) => {
    setKind(e.target.value);
  }

  return (
    <div>
      <MenuHeader />
      <BodyTable>
        <Table>
          <TableHead>
            <TableHeadRow>
              <label>Ordem</label>
            </TableHeadRow>
            <TableHeadRow>
              <label>Item de Menu</label>
            </TableHeadRow>
            <TableHeadRow>
              <label>Status</label>
            </TableHeadRow>
            <TableHeadRow>
              <label>Ação</label>
            </TableHeadRow>
          </TableHead>

          {items && (
            <SortableContainer onSortEnd={onSortEnd} useDragHandle>
              {items.map((value, index) => (
                <SortableItem index={index} value={value} />
              ))}
            </SortableContainer>
          )}
        </Table>
      </BodyTable>
      <Modal
        visible={props.alert.visible}
        width="736"
        height="410"
        effect="fadeInUp"
      >
        <form onSubmit={handleAddItem}>
          <MuiThemeProvider>
            <div className="dialogyesno-body">
              <div className="label-dialog-tag">Novo Item de Menu</div>
            </div>
            <div className="row form-group-input-row form-group-input-row-menu">
              <div className="offset-md-1 col-md-10 form-level">
                  <span class="form-modality-label form-modality-label-menu">Modalidade:</span>
                  <div
                    className="form-modality form-modality-menu"
                    onChange={handleExternalOrPublication}
                  >
                    <div>
                      <Field
                        name="kind"
                        component="input"
                        type="radio"
                        value="external_link"
                        id="external_link"
                        checked={kind === 'external_link'}
                      />
                      <label for="external_link" className="form-label">
                        Link externo
                      </label>
                    </div>
                    <div>
                      <Field
                        name="kind"
                        component="input"
                        type="radio"
                        value="publication"
                        id="publication"
                        checked={kind === 'publication'}
                      />
                      <label
                        for="publication"
                        className="form-label"
                      >
                        Publicação
                      </label>
                    </div>
                  </div>
              </div>
            </div>
            <div className="row">
              <div className="offset-md-1 col-md-10">
                <Field
                  name="title"
                  validate={[required]}
                  id="name"
                  floatingLabelText="Nome do item"
                  hintText=""
                  component={TextFieldDefault}
                  type="text"
                  style={{ width: '100%' }}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="offset-md-1 col-md-10">
                {
                  kind === "external_link" ?
                    <Field
                      name="link"
                      validate={[required]}
                      id="url"
                      floatingLabelText="Link de destino"
                      hintText=""
                      component={LongTextLink}
                      type="url"
                      style={{ width: '100%' }}
                      required
                    />
                    : kind === "publication" ? 
                      <Field
                        name="canvas_publication_id"
                        validate={[required, minValue0]}
                        id="canvas_publication_id"
                        floatingLabelText="Id da publicação"
                        hintText=""
                        style={{ width: '20%' }}
                        required
                        component={TextFieldNumberInput}
                        type="number"
                      /> : undefined
                }
                
              </div>
            </div>

            <div style={{ marginTop: '20px' }} className="row">
              <div className="offset-md-1 col-md-5">
                <ButtonDialogTag
                  onClick={props.closeDialog}
                  label="Cancelar"
                  type="button"
                />
              </div>
              <div className="col-md-5 ">
                <ButtonDialogTag type="submit" label="Salvar" />
              </div>
            </div>
          </MuiThemeProvider>
        </form>
      </Modal>
      <Modal visible={openEdit.show} width="736" height="371" effect="fadeInUp">
        <form onSubmit={handleEditItem}>
          <MuiThemeProvider>
            <div className="dialogyesno-body">
              <div className="label-dialog-tag">Editar Item de Menu</div>
            </div>
            <div />
            <div className="row">
              <div className="offset-md-1 col-md-10">
                <Field
                  name="title"
                  validate={[required]}
                  id="edit_name"
                  floatingLabelText="Nome do item"
                  hintText=""
                  component={TextFieldDefault}
                  type="text"
                  style={{ width: '100%' }}
                  required
                />
              </div>
            </div>
            {openEdit.kind === 'external_link' && (
              <div className="row">
                <div className="offset-md-1 col-md-10">
                  <Field
                    name="link"
                    validate={[required]}
                    id="url"
                    floatingLabelText="Link de destino"
                    hintText=""
                    component={LongTextLink}
                    type="url"
                    style={{ width: '100%' }}
                    required
                  />
                </div>
              </div>
            )}

            {openEdit.kind === 'publication' && (
              <div className="row">
                <div className="offset-md-1 col-md-10">
                  <Field
                    name="canvas_publication_id"
                    validate={[required, minValue0]}
                    id="canvas_publication_id"
                    floatingLabelText="Id da publicação"
                    hintText=""
                    style={{ width: '20%' }}
                    required
                    component={TextFieldNumberInput}
                    type="number"
                  />
                </div>
              </div>
            )}

            <div style={{ marginTop: '20px' }} className="row">
              <div className="offset-md-1 col-md-5">
                <ButtonDialogTag
                  onClick={() =>
                    setOpenEdit({
                      id: '',
                      link: undefined,
                      show: false,
                      height: '260'
                    })
                  }
                  label="Cancelar"
                  type="button"
                />
              </div>
              <div className="col-md-5 ">
                <ButtonDialogTag type="submit" label="Salvar" />
              </div>
            </div>
          </MuiThemeProvider>
        </form>
      </Modal>
      <DialogAlert
        closeDialog={props.closeDialog}
        message={props.erroalert.message}
        visible={props.erroalert.visible}
        createTag={props.createTag}
      />
    </div>
  );
}

let InitializeFromStateForm = reduxForm({
  form: 'edititemmenu',
  enableReinitialize: true
})(Menu);

const mapDispatchToProps = (dispatch) => {
  return {
    getMenu: async () => {
      await dispatch(MenuApi.getMenu());
    },
    deleteItemMenu: async (id) => {
      await dispatch(MenuApi.deleteItemMenu(id));
    },
    addItemMenu: (values) => {
      dispatch(MenuApi.addItemMenu(values));
    },
    editItemMenu: (values, id) => {
      dispatch(MenuApi.editItemMenu(values, id));
    },
    sortMenu: (values) => {
      dispatch(MenuApi.sortMenu(values));
    },
    openDialog: () => {
      dispatch(DialogApi.openDialog());
    },
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    }
  };
};

InitializeFromStateForm = connect(
  (state) => ({
    initalState: state.menu.item,
    menuList: state.menu.menuList,
    alert: state.dialog,
    erroalert: state.erroalert,
    tagMergeModalOpen: state.tag.tagMergeModalOpen
  }),
  mapDispatchToProps
)(InitializeFromStateForm);

export default InitializeFromStateForm;
