import React, { Component } from 'react';

import { style } from '../../style';
import ReactTooltip from 'react-tooltip';

import AcessIcon from './img/ic-link.svg';
import EditIcon from './img/ic-editar.svg';
import RemoveIcon from './img/ic-excluir.svg';
import eye from '../dashbord/dashboard_components/PublicationDash/img/ic-visualiza-oes@3x.png';
import resend_email from '../utils/img/resend_email.png';

require('../utils/table.css');
require('../utils/utils.css');

export class Table extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <table style={this.props.style} className="table table-responsive-sm">
        {this.props.children}
      </table>
    );
  }
}

export class TableHead extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return <thead className="header-label">{this.props.children}</thead>;
  }
}

export class TableBody extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return <tbody>{this.props.children}</tbody>;
  }
}

export class TableRow extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <tr
        onMouseOver={this.props.onMouseOver}
        value={this.props.value}
        onClick={this.props.onClick}
        className={this.props.className ? this.props.className : 'table-row'}
        onMouseLeave={this.props.onMouseLeave}
        style={this.props.style}
      >
        {this.props.children}
      </tr>
    );
  }
}

export class TableHeadRow extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <th style={this.props.style} className="text-header-row">
        {this.props.children}
      </th>
    );
  }
}

export class TableData extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <td
        data-tip={this.props.datatip}
        onClick={this.props.onClick}
        style={{ width: this.props.width }}
        className="font-text-table"
      >
        {this.props.children}
      </td>
    );
  }
}

export class RemoveEdit extends Component {
  render() {
    return (
      <div
        style={{ height: '100px', width: '130px' }}
        className="remover-edit-container"
      >
        <div>
          <div>
            <img
              onClick={this.props.enablesubmit ? ()=>{} : this.props.Delete}
              style={this.props.enablesubmit ? {cursor: "not-allowed"} : {}}
              data-tip="Excluir"
              className="imagicon"
              src={RemoveIcon}
              alt="ícone apagar"
            />
            {
              this.props.Edition && (
                <img
                  onClick={this.props.enablesubmit ? ()=>{} : this.props.Edition}
                  style={this.props.enablesubmit ? {cursor: "not-allowed"} : {}}
                  data-tip="Editar"
                  className="imagicon"
                  src={EditIcon}
                  alt="Ícone de edição"
                />
              )
            }
            {this.props.edit !== false ? (
              <img
                onClick={this.props.enablesubmit ? ()=>{} : this.props.Edit}
                style={this.props.enablesubmit ? {cursor: "not-allowed"} : {}}
                data-tip="Editar"
                className="imagicon"
                src={EditIcon}
                alt="Ícone de edição"
              />
            ) : (
              <img
                onClick={this.props.enablesubmit ? ()=>{} : this.props.View}
                style={this.props.enablesubmit ? {cursor: "not-allowed"} : {}}
                data-tip="Visualizar"
                className="imagicon eye"
                src={eye}
                alt="Ícone de visualização"
              />
            )}
            {this.props.sendToken && style.hasToken && (
              <img
                onClick={this.props.enablesubmit ? ()=>{} : this.props.sendToken}
                style={this.props.enablesubmit ? {cursor: "not-allowed"} : {}}
                data-tip="Reenviar Token"
                className="imagicon-token"
                src={resend_email}
                alt="Ícone de envar token"
              />
            )}
            <ReactTooltip className="tooltip" />
          </div>
        </div>
      </div>
    );
  }
}
export default RemoveEdit;

export class RemoveEditAcess extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className="row">
        <img
          onClick={this.props.Delete}
          data-tip="Excluir"
          className="imagicon"
          src={RemoveIcon}
          alt="ícone apagar"
        />
        <img
          onClick={this.props.Edit}
          data-tip="Editar"
          className="imagicon"
          src={EditIcon}
          alt="ícone editar"
        />
        <img
          onClick={this.props.Show}
          data-tip="Acessar"
          className="imagicon"
          src={AcessIcon}
          alt="ícone acessar"
        />
        <ReactTooltip className="tooltip" />
      </div>
    );
  }
}

export class EditTable extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-2">
          <div onClick={this.props.Edit} data-tip="Editar" className="imagicon">
            <img src={EditIcon} alt="ícone editar" />
          </div>
        </div>
        <ReactTooltip className="tooltip" />
      </div>
    );
  }
}

export class RemoveEditAcessFile extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className="row">
        <div
          onClick={this.props.Delete}
          data-tip="Excluir"
          className="imagicon"
        >
          <img src={RemoveIcon} alt="ícone apagar" />
        </div>
        <div onClick={this.props.Edit} data-tip="Editar" className="imagicon">
          <img src={EditIcon} alt="ícone editar" />
        </div>
        <div onClick={this.props.Show} data-tip="Acessar" className="imagicon">
          <a href={this.props.filelink} target="_blank">
            {' '}
            <img src={AcessIcon} alt="ícone acessar" />{' '}
          </a>
        </div>

        <ReactTooltip className="tooltip" />
      </div>
    );
  }
}
