import React, { Component } from 'react';
import { Table, TableBody, TableRow, TableRowColumn } from 'material-ui/Table';
import { Avatar } from 'material-ui';
import PlaceHolder from '../PlaceHolder/PlaceHolder';

require('./userlistdash.css');

class UserListDash extends Component {
  render() {
    return (
      <div className="body-user-list-dash">
        <div className="header-user-list">
          <label className="main-label-user-engage">{this.props.label}</label>
        </div>

        {this.props.user_list.length === 0 ? (
          <PlaceHolder />
        ) : (
          <Table height="300px">
            <TableBody displayRowCheckbox={false}>
              {Array.isArray(this.props.user_list)
                ? this.props.user_list.map(function(user) {
                    return (
                      <TableRow displayBorder={false}>
                        <TableRowColumn>
                          <div className="flex-avatar-name">
                            <Avatar src={user.image}></Avatar>
                            <label className="label-name-user">
                              {user.name.slice(0, 15)}
                            </label>
                          </div>
                        </TableRowColumn>
                        <TableRowColumn>
                          <div className="flex-avatar-img">
                            <label className="label-score-dash">
                              {user.interactions_count === null
                                ? 0
                                : user.interactions_count.toLocaleString("pt-BR")}
                            </label>
                          </div>
                        </TableRowColumn>
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        )}
      </div>
    );
  }
}
export default UserListDash;
