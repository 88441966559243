import React, { Component, Fragment } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeadRow,
  TableData,
  RemoveEditAcess
} from '../utils/Table';
import ComponentHeader from '../utils/ComponenteHeader';
import { browserHistory } from 'react-router';
import { BodyTable } from '../utils/BodyTable';
import IcoFolder from '../utils/img/ic-pasta.png';
import { Avatar } from 'material-ui';
import { connect } from 'react-redux';

import RepositorieApi from '../../Logicas/RepositoryApi';
import GaleryApi from '../../Logicas/GalleryApi';
import { DialogYesNo } from '../utils/DailogComponentes';
import DialogApi from '../../Logicas/DialogApi';
import { FooterPagination } from '../utils/Pagination';
import moment from 'moment-timezone';
class FileList extends Component {
  constructor() {
    super();
    this.state = {
      init: 0,
      activePage: 1
    };
  }

  componentDidMount() {
    this.props.getFolders(1);
  }

  getPagination(page) {
    this.props.getFolders(page);
  }

  render() {
    if (this.props.list.length === 0 || !this.props.list.pagination)
      return <></>;

    return (
      <Fragment>
        <ComponentHeader
          actions={[
            { name: 'Galerias', action: () => this.props.getGalleriesList() }
          ]}
          name="Arquivos"
          nameaction="Adicionar pasta +"
          onClick={function() {
            browserHistory.push('/folder/create');
          }}
        />

        <BodyTable>
          <Table>
            <TableHead>
              <TableHeadRow>
                <label
                  onMouseLeave={this.hideorderico}
                  onMouseOver={this.showorderico}
                >
                  Id{this.state.oderico}
                </label>
              </TableHeadRow>
              <TableHeadRow>
                <label
                  onMouseLeave={this.hideorderico}
                  onMouseOver={this.showorderico}
                >
                  Nome{this.state.oderico}
                </label>
              </TableHeadRow>
              <TableHeadRow>
                <label
                  onMouseLeave={this.hideorderico}
                  onMouseOver={this.showorderico}
                >
                  Criado em
                </label>
              </TableHeadRow>
              <TableHeadRow>
                <label
                  onMouseLeave={this.hideorderico}
                  onMouseOver={this.showorderico}
                >
                  Criado por
                </label>
              </TableHeadRow>
              <TableHeadRow>
                <label
                  onMouseLeave={this.hideorderico}
                  onMouseOver={this.showorderico}
                >
                  Tags
                </label>
              </TableHeadRow>
              <TableHeadRow>
                <label
                  onMouseLeave={this.hideorderico}
                  onMouseOver={this.showorderico}
                >
                  Ações
                </label>
              </TableHeadRow>
            </TableHead>

            <TableBody>
              {Array.isArray(this.props.list.repositories) &&
                this.props.list.repositories.map((repository, index) => {
                  return (
                    <TableRow value={index}>
                      <TableData>
                        <div
                          style={{
                            marginTop: '10px'
                          }}
                        >
                          {repository.id}
                        </div>
                      </TableData>
                      <TableData>
                        <div className="row">
                          <div className="col-md-2">
                            <img
                              style={{
                                marginTop: '4px'
                              }}
                              src={IcoFolder}
                              alt="ícone pasta"
                            />
                          </div>
                          <div className="col-md-6">
                            <div
                              style={{
                                marginTop: '10px'
                              }}
                            >
                              {repository.name_folder.length > 75
                                ? `${repository.name_folder.slice(0, 75)}...`
                                : repository.name_folder}
                            </div>
                          </div>
                        </div>
                      </TableData>
                      <TableData>
                        <div
                          style={{
                            marginTop: '10px'
                          }}
                        >
                          {moment
                            .tz(repository.created_at, 'Europe/Berlin')
                            .format('DD/MM/YYYY')}
                        </div>
                      </TableData>
                      <TableData>
                        <div className="row">
                          <div className="col-md-2">
                            <Avatar src={repository.user.image.url} size={30} />
                          </div>
                          <div className="col-md-6">
                            <div
                              style={{
                                marginTop: '10px'
                              }}
                            >
                              {repository.user.name}
                            </div>
                          </div>
                        </div>
                      </TableData>
                      <TableData>
                        <div
                          style={{
                            marginTop: '10px'
                          }}
                        >
                          {repository.tags[0] !== undefined &&
                            repository.tags[0].name}
                        </div>
                      </TableData>
                      <TableData>
                        <div
                          style={{
                            marginTop: '10px'
                          }}
                        >
                          <RemoveEditAcess
                            Delete={this.props.deleteShowDialogFolder.bind(
                              this,
                              repository.id
                            )}
                            Show={this.props.getFiles.bind(this, repository.id)}
                            Edit={this.props.editFolder.bind(
                              this,
                              repository.id
                            )}
                          />
                        </div>
                      </TableData>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <FooterPagination
            activePage={this.props.list.pagination.current_page}
            handlePageChange={this.getPagination.bind(this)}
            totalItemsCount={this.props.list.pagination.total_count}
          />
          <DialogYesNo
            yesclick={this.props.deleteFolder.bind(
              this,
              this.props.alert.id_elemente
            )}
            noclick={this.props.closeDialog}
            onClick={this.props.closeDialog}
            visible={this.props.alert.visible}
            message="Tem certeza que deseja remover esta pasta ?"
          />
        </BodyTable>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.repositories,
    alert: state.dialog,
    pagination: state.pagination
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getFolders: (page, per_page) => {
      dispatch(RepositorieApi.getFolders(page, per_page));
    },
    editFolder: (id, values) => {
      dispatch(RepositorieApi.editFolder(id));
    },
    getFiles: (id) => {
      dispatch(RepositorieApi.getFiles(id));
    },
    deleteShowDialogFolder: (values, id) => {
      dispatch(DialogApi.deleteAlert(values));
    },
    deleteFolder: (values, id) => {
      dispatch(RepositorieApi.deleteFolder(values));
    },
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    },
    getGalleriesList: () => {
      dispatch(GaleryApi.getGalleries(1));
    }
  };
};
const FoldersListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileList);

export default FoldersListContainer;
