import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-awesome-modal';
import { MuiThemeProvider } from 'material-ui/styles';
import { Avatar } from 'material-ui';
import PostApi from '../../../../Logicas/PostApi';

import IcoExit from '../../../utils/img/exit-dialog.png';
import { TextFieldWithoutForm } from '../../FormsComponents';
import { HeaderDialog } from '../style_dialogs';
import PubSub from 'pubsub-js';

require('./DialogueViews.css');

class DialogViews extends Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      request: true
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.getByName = this.getByName.bind(this);
  }

  componentWillMount() {
    window.onscroll = () => this.handleScroll();
    PubSub.subscribe(
      'get_next_page_comments',
      function(render) {
        this.setState({ request: true });
      }.bind(this)
    );
  }

  handleScroll() {
    if (document.getElementById('#body-scroll') !== null) {
      let scrollposition = document.getElementById('#body-scroll').scrollTop;
      var max = document.getElementById('#body-scroll').scrollHeight;
      if (
        scrollposition > max / 3 &&
        this.props.views_list.length % 10 === 0 &&
        this.state.request
      ) {
        this.setState({ page: (this.state.page += 1), request: false }, () => {
          this.props.getViews({
            id: this.props.id_post,
            page: this.state.page
          });
        });

        document
          .getElementById('#body-scroll')
          .scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }
  }

  getByName(value) {
    if (value.target.value.length >= 3) {
      this.setState({ page: 1 });
      this.props.getViews({
        id: this.props.id_post,
        page: 1,
        name: value.target.value,
        reset: true
      });
    }
    if (value.target.value.length === 0) {
      this.setState({ page: 1 });
      this.props.getViews({ id: this.props.id_post, page: 1, reset: true });
    }
    this.setState({ searchvalue: value.target.value });
  }

  render() {
    let visible = this.props.visible;

    return (
      <Modal visible={visible} width="35%" height="70%" effect="fadeInUp">
        <MuiThemeProvider>
          <HeaderDialog />
          <div className="header-row-views">
            <div className="label-views">
              {this.props.views_list.length === 1
                ? this.props.views_list.length + ' Visualização única'
                : this.props.views_list.length + ' Visualizações únicas'}{' '}
            </div>
          </div>
          <div onClick={this.props.closeDialog} className="header-row-exit">
            <img
              style={{
                width: '18px',
                height: '18px',
                marginRight: '20px',
                marginTop: '-15%'
              }}
              src={IcoExit}
              alt="fechar"
            />
          </div>
          <div
            id="#body-scroll"
            onScroll={this.handleScroll}
            className="body-views"
          >
            <div className="field-search">
              <TextFieldWithoutForm
                value={this.state.searchvalue}
                onChange={this.getByName}
                floatingLabelText="Busque por usuário"
              />
            </div>
            {Array.isArray(this.props.views_list)
              ? this.props.views_list.map(
                  function(user) {
                    return (
                      <div className="col-views">
                        <div className="row-views">
                          <Avatar src={user.image.url} />
                          <div className="col-row-views">
                            <div className="user-name-views">{user.name}</div>
                          </div>
                        </div>
                      </div>
                    );
                  }.bind(this)
                )
              : null}
          </div>
        </MuiThemeProvider>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeDialog: () =>
      dispatch({ type: 'GET_VIEWS', data: [], visible: false }),
    getViews: (param) => {
      dispatch(PostApi.getViews(param));
    }
  };
};

let DialogViewsContainer = connect(
  (state) => ({
    views_list: state.post_views.data,
    id_post: state.post_views.id_post
  }),
  mapDispatchToProps
)(DialogViews);

export default DialogViewsContainer;
